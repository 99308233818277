<script setup lang="ts">
import { Dayjs } from "dayjs";

import type { Promotion, Badge, HowWorks } from "@/types";

const { t } = useT();

const { open } = useNlcModals();

const props = defineProps<{
	hideLabel?: boolean;
	availableSoon?: boolean;
	image: string;
	type?: Promotion["type"];
	howWorks?: HowWorks;
	bestDeal?: boolean;
	mostPopular?: boolean;
	showBadge?: boolean;
	imageBadge?: string;
	icon?: string;
	badge?: Badge;
	subTitle?: string;
	title: string;
	coins?: number;
	entries?: number;
	showAvailable?: boolean;
	enabled?: boolean;
	date?: Dayjs | string;
	secretOffer?: boolean;
	money?: string;
	textTooltip?: string;
	usualPrice?: string | null;
	nameButton?: string;
	buttonTid?: string | null;
	coinsTid?: string | null;
	entriesTid?: string | null;
}>();

const emit = defineEmits<{ (event: "click"): void }>();
const { ready: readyAnimation, start: handleStartAnimation } = useTimeout(2000, { controls: true, immediate: false });
const { ready: readyPopper, start: handleStartPopper } = useTimeout(2000, { controls: true, immediate: false });

const nameButtonEnabled = computed(() => {
	if (!props.enabled) {
		return "";
	}
	if (props.secretOffer) {
		return t("Unlock");
	}

	return `Buy Now $${props.money ?? 0}`;
});

const handleClick = () => {
	if (props.availableSoon) {
		handleStartAnimation();
		handleStartPopper();
		return;
	}

	emit("click");
};

const handleClickInfo = () => {
	if (!props.howWorks?.cardHowItWorks) {
		return;
	}

	return open("LazyOModalPromoHowItWorks", { howWorks: props.howWorks, type: props.type });
};

const usualTextColor = computed(() => `color: ${props.badge?.promoUsualTextColor}`);
</script>

<template>
	<MPromotionWrapper
		:type="type"
		:title="(enabled && !secretOffer) || availableSoon ? '' : title"
		:image="image"
		:backgroundColor="badge?.offerBackground"
		:buttonName="nameButton || nameButtonEnabled"
		:buttonTid="buttonTid"
		:buttonInfo="!!howWorks?.cardHowItWorks"
		@click-info="handleClickInfo"
		@click-card="handleClick"
	>
		<template v-if="!secretOffer" #top>
			<MPromotionLabel v-if="!hideLabel" :bestDeal="bestDeal" :mostPopular="mostPopular" :badge="badge" :icon="icon" />
			<AText v-if="subTitle && enabled" :modifiers="['bold', 'center']">{{ subTitle }}</AText>
			<MPromotionBadge v-if="badge && imageBadge" :image="imageBadge" :badge="badge" />
		</template>

		<div :class="['box', { 'box-secret': secretOffer }]">
			<AText v-if="subTitle && !enabled" :modifiers="['bold', 'center']">{{ subTitle }}</AText>

			<MPromotionCoins
				v-if="!secretOffer"
				:coins="coins"
				:entries="entries"
				:coinsTid="coinsTid"
				:entriesTid="entriesTid"
			/>

			<MCounterBadgeGroup v-if="showAvailable && enabled" :timestamp="date ?? ''" :badgeText="t('Limited Offer')" />

			<AText v-if="secretOffer" :size="22" class="description" :modifiers="['uppercase', 'bold', 'center']">
				<i18n-t keypath="1 step to unlock the offer and get {key} for free">
					<template #key>
						<span class="color-secondary-60">sc {{ numberFormat(entries ?? 0) }}</span>
					</template>
				</i18n-t>
			</AText>
			<AText v-else-if="usualPrice" :style="usualTextColor" :modifiers="['uppercase']">
				<b>{{ t("was") }}</b> $<s>{{ usualPrice }}</s>
			</AText>
			<AText v-if="type === 'emailLottery'" :modifiers="['bold', 'center']">{{ t("Update your profile") }}</AText>
		</div>

		<template #bottom>
			<APopper
				v-if="money && availableSoon"
				class="btn-not-allowed"
				:content="textTooltip"
				placement="top"
				:show="!readyPopper"
			>
				<AButton variant="primary" data-tid="promo-not-allowed" :modifiers="['disabled', 'wide']">
					<AAnimationTada :active="!readyAnimation">
						<NuxtIcon name="16/lock" filled />
					</AAnimationTada>
					<AText :size="16" :modifiers="['normal', 'uppercase']">${{ money }}</AText>
				</AButton>
			</APopper>
		</template>
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.card:deep(.top) {
	text-align: center;
}

.box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;

	&.box-secret {
		justify-content: center;

		.description {
			padding: 16px;
		}
	}
}

.btn-info {
	position: absolute;
	z-index: 2;
	cursor: pointer;
	top: 8px;
	right: 16px;

	.nuxt-icon {
		font-size: 32px;
	}
}

.bottom button {
	&:deep(svg) {
		font-size: 16px;
		margin: 0;
	}

	&.disabled:deep(svg) {
		font-size: 26px;
	}
}

.card-inviteQualification:deep(.box-content > .box) {
	flex-direction: column-reverse;
}

.card-partnerReferralQualification {
	.box-coins {
		margin: auto 0 20px;
	}

	&:deep(.title) {
		width: 210px;
		margin: 24px auto -24px;
	}
}

.btn-not-allowed {
	width: 100%;
}
</style>
